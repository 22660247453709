<template>
  <portal to="export-info-modal">
    <div class="export-info-modal" @click="closeModal"></div>
    <div v-if="exportInfoMode === 'warning'" class="export-info-wrap">
      <div class="header">
        <img src="@/assets/icons/warning-red.svg" alt="" />{{
          $t('export_warning_title')
        }}
      </div>
      <div class="content">
        <p>{{ $t('export_warning_content') }}</p>
        <div class="btn" @click="closeModal">{{ $t('confirm') }}</div>
      </div>
    </div>
    <div v-else class="custom-filename-wrap">
      <div class="head">{{ $t('export_result') }}</div>
      <div class="title">{{ $t('export_custom_filename')/*檔案名稱*/ }}</div>
      <TooltipElement :content="exportFilename" placement="bottom">
        <input v-model="exportFilename" type="text" />
      </TooltipElement>

      <div class="export-options">
        <div 
          v-for="(item, key) in exportOptions" 
          :key="key" 
          class="item"
          :class="{ disabled: historyTotal > maxExportPhotoCount }"
          @click="item.value = item.value === 1 ? 0 : 1"  
        >
          <img v-if="item.value === 1" src="@/assets/icons/checkbox-yellow-checked.svg" alt="">
          <img v-else src="@/assets/icons/checkbox-white-square.svg" alt="">
          <span>{{ $t(item.label) }}</span>
        </div>
        <div v-if="historyTotal > maxExportPhotoCount" class="warning">
          <img src="@/assets/icons/error-info.svg" alt="">
          <span>{{ $t('export_photo_warning', {count: maxExportPhotoCount}) }}</span>
        </div> 
      </div>
      
      <div class="btns">
        <div class="btn cancel" @click="cancelExport">{{ $t('cancel') }}</div>
        <div class="btn" @click="handleExport">{{ $t('confirm') }}</div>
      </div>
    </div>
  </portal>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'ExportInfoModal',
  components: {
    TooltipElement: () => import('@/components/Base/TooltipElement.vue'),
  },
  data() {
    return {
      maxExportPhotoCount: 30000, // 最大匯出照片數量，超過此數量會有警告，無法匯出圖片
      defaultFilename: '',
      exportFilename: '',
      exportOptions: {
        photo: { 
          label: this.$t('export_license_plate_picture'), 
          value: 0 
        },
        snapshot: { 
          label: this.$t('export_snapshot'), 
          value: 0 
        },
        embed: { 
          label: this.$t('export_license_plate_picture_embedded'), 
          value: 0 
        },
      },
    }
  },
  computed: {
    ...mapState('history', [
      'historyTotal',
      'searchMode', 
      'exportInfoMode', 
      'getEventsParams', 
      'exportList'
    ]),
    ...mapGetters(['getUserInfo', 'timezone']),
  },
  mounted() {
    if (this.$route.query.maxExpPhoto) {
      this.maxExportPhotoCount = parseInt(this.$route.query.maxExpPhoto)
    }
    console.log('maxExportPhotoCount:', this.maxExportPhotoCount)

    const nowTime = moment().tz(this.timezone).format('YYYYMMDDHHmmss')
    if (this.searchMode === 'basic') {
      const startDate = moment(this.getEventsParams.afterDetectTime).tz(this.timezone).format('YYYYMMDD')
      const endDate = moment(this.getEventsParams.beforeDetectTime).tz(this.timezone).format('YYYYMMDD')
      if (this.getEventsParams.content) {
        // 一般查詢：依“車號”搜尋 <搜查車號>_<開始日期>-<結束日期>_<查詢者姓名(帳號)>_<查詢時間>.zip
        // 例：ABC-1234_20220130-20220430_王大明(typd001)_20220816182755.zip
        this.defaultFilename = `${this.getEventsParams.content}_${startDate}-${endDate}_${this.getUserInfo.name}(${this.getUserInfo.id})_${nowTime}`
      } else {
        // 一般查詢：依“設備”搜尋 <選擇的設備id>_<開始日期>-<結束日期>_<查詢者姓名(帳號)>_<查詢時間>.zip
        // 例：選擇的設備id_20231201-20231201_(manager)_20231201235805
        this.defaultFilename = `${this.getEventsParams.user}_${startDate}-${endDate}_${this.getUserInfo.name}(${this.getUserInfo.id})_${nowTime}`
      }
    } else {
      // 進階查詢：進階查詢_<查詢人姓名(帳號)>_<查詢時間>.zip
      // 例：進階查詢_王大明(typd001)_20220816182755.zip
      this.defaultFilename = `${this.$t('export_adv')}_${this.getUserInfo.name}(${this.getUserInfo.id})_${nowTime}`
    }
    this.exportFilename = this.defaultFilename
  },
  methods: {
    ...mapMutations('history', ['updateShowExportInfoModal']),
    ...mapActions('history', ['exportBasicReport', 'exportAdvancedReport']),
    closeModal() {
      this.updateShowExportInfoModal(false)
    },
    cancelExport() {
      this.closeModal()
    },
    handleExport() {
      if (this.exportFilename === '') this.exportFilename = this.defaultFilename
      // photo: 小圖, snapshot: 大圖, embed: 嵌入小圖至Excel
      const payload = {
        description: this.exportFilename,
        photo: this.exportOptions.photo.value,
        snapshot: this.exportOptions.snapshot.value,
        embed: this.exportOptions.embed.value,
      }
      if (this.searchMode === 'basic')
        this.exportBasicReport(payload) // 一般查詢的匯出
      else if (this.searchMode === 'advanced')
        this.exportAdvancedReport(payload) // 進階查詢的匯出
      this.closeModal()
    },
  }
}
</script>

<style lang="scss" scoped>
.export-info-modal {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 44, 57, 0.6);
  z-index: 5;
}

.export-info-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 780px;
  z-index: 5;
}

.header {
  background: #4a5c78;
  color: #ffffff;
  border-radius: 23px 23px 0px 0px;
  font-size: 23px;
  font-weight: 300;
  padding: 15px 32px;
  display: flex;
  align-items: center;
}

.header img {
  width: 24px;
  height: 24px;
  margin-right: 22px;
}

.content {
  background: #282942;
  border-radius: 0px 0px 23px 23px;
  text-align: center;
  position: relative;
  top: -32px;
}

.content > p {
  font-size: px2rem(28);
  line-height: 44px;
  font-weight: 300;
  color: #ffffff;
  padding-top: 40px;
  padding-bottom: 20px;
}

.btn {
  display: inline-block;
  background: #ffc600;
  border-radius: 8px;
  font-size: px2rem(18);
  font-weight: 300;
  color: #282942;
  padding: 10px 48px;
  margin-bottom: 50px;
  cursor: pointer;
}

.btn:hover {
  background: #ffd231;
}

/* 自訂檔名 */
.custom-filename-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 480px;
  padding: 32px;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  color: #ffffff;
  background: #282942;
  border: 1px solid #4a5c78;
  z-index: 5;

  .head {
    font-size: px2rem(28);
    line-height: 48px;
    display: flex;
    justify-content: center;
    margin-bottom: 6px;
  }

  .title {
    font-size: px2rem(16);
    font-weight: 400;
    line-height: 36px;
    color: #ffffff80;
  }
}

.export-options {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-bottom: 48px;
  .item {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    
    &.disabled {
      opacity: 0.2;
      pointer-events: none;
    }

    img {
      width: 18px;
      height: 18px;
      padding-top: 5px;
      margin-right: 12px;
    }
    span {
      font-size: px2rem(18);
      line-height: 24px;
    }
  }

  .warning {
    display: flex;
    padding-top: 12px;
    img {
      width: 18px;
      height: 18px;
      padding-top: 5px;
      margin-right: 12px;
    }
  }
}

.custom-filename-wrap input {
  width: 100%;
  height: 48px;
  outline: none;
  border: 1px solid #9d9d9d;
  border-radius: 8px;
  font-size: px2rem(18);
  font-weight: 400;
  line-height: 36px;
  padding: 6px 12px;
  margin-bottom: 20px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.custom-filename-wrap .sub-title {
  font-size: px2rem(20);
  line-height: 36px;
  position: relative;
  margin-bottom: 8px;
}

.custom-filename-wrap .sub-title::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #ffffff80;
  left: 0;
  top: -24px;
}

.custom-filename-wrap .query {
  margin-bottom: 24px;
}

.custom-filename-wrap .query-item {
  font-size: px2rem(20);
  line-height: 35px;
  margin-bottom: 8px;
}

.custom-filename-wrap .query-item .key {
  color: #ffffff80;
}

.custom-filename-wrap .btns {
  display: flex;
  justify-content: center;
  column-gap: 32px;
}

.btns .btn {
  margin-bottom: 0px;
}

.btns .btn.cancel {
  border: #ffffff 1px solid;
  background: #282942;
  color: #ffffff;
}

.btns .btn.cancel:hover {
  background: #ffffff33;
}
</style>